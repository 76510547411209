import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTransition, animated } from 'react-spring';
import styles from './spinner.module.scss';
type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  blur?: boolean;
  blurValue?: Number;
  extras?: string;
  isSpinner?: boolean;
  texts?: string[];
  disableBodyScroll?: boolean;
};

const CustomSpinner = ({
  children,
  isLoading = false,
  blur = true,
  blurValue = 2,
  extras = '',
  isSpinner = true,
  texts = ['Loading', 'Loading.', 'Loading..'],
  disableBodyScroll = false,
}: Props) => {
  const [index, setIndex] = React.useState(0);
  const transitions = useTransition(texts[index], item => item, {
    from: { opacity: 0,  },
    enter: { opacity: 1 },
    leave: { opacity: 0 },

  });
  React.useEffect(() => {
    if (!isSpinner) {
      const interval = setInterval(() => {
        setIndex(prevIndex => (prevIndex + 1) % texts.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [texts.length, isSpinner]);
  React.useEffect(() => {
    if (disableBodyScroll) {
      if (isLoading) {
        document.body.style.overflow = 'hidden'; // Disable scrolling on the body element
      } else {
        document.body.style.overflow = 'auto'; // Enable scrolling on the body element
      }
    }
  }, [isLoading]);
  
  return (
    <span className="customSpinnerContainer">
      <span
        style={isLoading ? { filter: `blur(${blur ? blurValue : 0}px)` } : {}}
        className={`customSpinnerChildren ${isLoading ? 'disableEvents' : ''} `}
      >
        {children}
      </span>
      {isLoading ? (
        isSpinner ? (
          <Spinner
            className={`customSpinner ${extras}`}
            // style={{ position: 'absolute' }}
            animation="border"
            role="status"
          />
        ) : (
          <div className={`customSpinner ${extras}`}>
            {transitions.map(({ item, key, props }) => {
              return (
                <animated.span style={{ ...props }}>
                  <h3 style={{ marginLeft: '5px' }}>{item}</h3>
                </animated.span>
              );
            })}
          </div>
        )
      ) : (
        <></>
      )}
    </span>
  );
};

export default CustomSpinner;
