import React, { Suspense } from 'react';

import '../styles/custom.scss';
import '../styles/main.scss';
import { HeaderOffer } from 'components/HeaderOffer';
import Loader from 'components/common/Loader';
import { isBrowser } from '../utils';

interface Props {
  children: React.ReactNode;
  showDiscountHeader?: boolean;
}

export default (props: Props) => {
  const { showDiscountHeader, children } = props;
  return isBrowser() ? (
    <Suspense fallback={<Loader />}>
      <div className="page-wrapper">
        {showDiscountHeader && <HeaderOffer />}
        <div>{children}</div>
      </div>
    </Suspense>
  ) : null;
};
